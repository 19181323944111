import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AdminProfileComponent } from '../admin-profile/admin-profile.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: [],
})
export class AdminMenuComponent {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private dialog: MatDialog
  ) {}

  onProfileClick() {
    // open admin profile in the popup
    this.dialog.open(AdminProfileComponent);
  }

  onChangePassword() {
    // open admin profile in the popup
    this.dialog.open(ChangePasswordComponent, {
      width: '450px',
    });
  }

  onLogout() {
    this.storageService.setAccessToken('');
    this.storageService.setRefreshToken('');
    this.storageService.setAdmin(null);

    this.router.navigate(['/auth']);
  }
}
