import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Admin } from '../../../../../../modules/database/admin/admin.model';
import { AdminService } from 'src/app/shared/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
})
export class AdminProfileComponent implements OnInit {
  adminForm!: FormGroup;
  currentAdmin!: Admin | null;

  constructor(
    private storageService: StorageService,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<AdminProfileComponent>
  ) {}

  ngOnInit(): void {
    this.currentAdmin = this.storageService.getAdmin();
    this.getProfile();
    this.initForm();
  }

  initForm() {
    this.adminForm = new FormGroup({
      name: new FormControl(this.currentAdmin?.name, [Validators.required]),
      email: new FormControl(this.currentAdmin?.email, [
        Validators.required,
        Validators.email,
      ]),
    });
  }

  updateProfile() {
    if (this.adminForm.valid) {
      this.spinner.show();
      this.adminService.updateProfile(this.adminForm.value).subscribe(
        (admin) => {
          this.spinner.hide();
          this.storageService.setAdmin(admin);
          this.currentAdmin = admin;
        },
        (error) => {
          this.spinner.hide();
          console.error(error);
        }
      );
    }
  }

  getProfile() {
    this.adminService.getProfile().subscribe(
      (admin) => {
        this.adminForm.patchValue({
          name: admin.name,
          email: admin.email,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }
}
