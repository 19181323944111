<!-- Menu -->
<button mat-menu-item (click)="onProfileClick()">
  <mat-icon>account_box</mat-icon> Profile
</button>
<button mat-menu-item (click)="onChangePassword()">
  <mat-icon>vpn_key</mat-icon> Change Password
</button>
<button mat-menu-item (click)="onLogout()">
  <mat-icon>exit_to_app</mat-icon> Sign Out
</button>
