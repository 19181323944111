export enum Jobs {
  /**
   * Game Event Scheduler
   */
  AbortGameAfterInitialWait = "abortGameAfterInitialWait",
  InitializeBotPlayer = "initializeBotPlayer",
  TurnForfeitAfterTimeout = "turnForfeitAfterTimeout",
  StartGame = "startGame",
  EndGame = "endGame",
  ChangeTurn = "changeTurn",
  PlayTurn = "playTurn",
  YellUnoTimeout = "yellUnoTimeout",
  /**
   * Utility Scheduler
   */
  RoomCleanUp = "roomCleanUp",

  CleanThirteenSession = "cleanThirteenSession",
  TurnTimeout = "turnTimeout",
}
