<h2 class="font-medium" mat-dialog-title>
  <strong>Change Password </strong>
</h2>
<mat-dialog-content>
  <form [formGroup]="passwordForm">
    <mat-form-field class="w-100">
      <input
        matInput
        [type]="hideOldPassword ? 'password' : 'text'"
        placeholder="Old Password"
        formControlName="oldPassword"
      />
      <mat-icon matSuffix (click)="hideOldPassword = !hideOldPassword">{{
        hideOldPassword ? "visibility" : "visibility_off"
      }}</mat-icon>

      <mat-error *ngIf="passwordForm.controls.oldPassword.hasError('required')">
        Old password is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        [type]="hideNewPassword ? 'password' : 'text'"
        matInput
        placeholder="New Password"
        formControlName="newPassword"
      />
      <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">{{
        hideNewPassword ? "visibility" : "visibility_off"
      }}</mat-icon>
      <mat-error *ngIf="passwordForm.controls.newPassword.hasError('required')">
        New password is required.
      </mat-error>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input
        [type]="hideConfirmPassword ? 'password' : 'text'"
        matInput
        placeholder="Confirm Password"
        formControlName="confirmPassword"
      />
      <mat-icon
        matSuffix
        (click)="hideConfirmPassword = !hideConfirmPassword"
        >{{ hideConfirmPassword ? "visibility" : "visibility_off" }}</mat-icon
      >
      <mat-error
        *ngIf="passwordForm.controls.confirmPassword.hasError('required')"
      >
        Confirm password is required.
      </mat-error>
      <mat-error
        *ngIf="
          passwordForm.controls.confirmPassword.hasError('confirmValidator')
        "
      >
        Confirm password is not matched with new password.
      </mat-error>
    </mat-form-field>
  </form>
  <button
    mat-button
    mat-flat-button
    color="warn"
    [disabled]="!passwordForm.valid"
    (click)="changePassword()"
  >
    Submit
  </button>
  <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
</mat-dialog-content>
