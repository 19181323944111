import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptService {
  constructor() {}

  encrypt(value: string) {
    var key = CryptoJS.enc.Utf8.parse(environment.passwordSecret);
    var iv = CryptoJS.enc.Utf8.parse(environment.passwordSecret);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  decrypt = (value: string) => {
    const key = CryptoJS.enc.Utf8.parse(environment.passwordSecret);
    const iv = CryptoJS.enc.Utf8.parse(environment.passwordSecret);
    const decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  };
}
