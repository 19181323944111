import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { Admin } from '../../../../../../modules/database/admin/admin.model';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-vertical-header',
  templateUrl: './vertical-header.component.html',
  styleUrls: [],
})
export class VerticalAppHeaderComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  admin!: Admin | null;

  constructor(
    private translate: TranslateService,
    private storageService: StorageService
  ) {
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.admin = this.storageService.getAdmin();
  }

  changeLanguage(lang: any): void {
    this.translate.use(lang.code);
  }
}
