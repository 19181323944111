export enum ChartFilterType {
  LastSevenDays = 0,
  LastMonth = 1,
  LastSixMonths = 2,
  LastYear = 3,
  LastSevenDaysTitle = "Last 7 Days",
  LastMonthTitle = "Last Month",
  LastSixMonthsTitle = "Last 6 Months",
  LastYearTitle = "Last year",
  LastSevenDaysMultiplier = 7,
  LastMonthMultiplier = 30,
  LastSixMonthsMultiplier = 180,
  LastYearMultiplier = 365,
}
