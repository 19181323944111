import { Injectable } from '@angular/core';
import {
  ImageMimeType,
  VideoMimeType,
  ChartFilterType,
  GameMode,
} from '../../../../../modules/enums';
import { mimeTypes } from 'mime-wrapper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransactionMode } from '../../../../../modules/database/transaction/transaction-mode.enum';
import { TransactionStatus } from '../../../../../modules/database/transaction/transaction-status.enum';
import { TransactionType } from '../../../../../modules/database/transaction/transaction-type.enum';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  private imageMimeTypes: string[] = Object.values(ImageMimeType);
  private videoMimeTypes: string[] = Object.values(VideoMimeType);

  constructor(private snackBar: MatSnackBar) {}

  toast(message: string) {
    this.snackBar.open(message, undefined, {
      duration: 3000,
      politeness: 'polite',
      horizontalPosition: 'right',
    });
  }

  classGameMode(type: string): string {
    switch (type) {
      case GameMode[GameMode.PUBLIC].toString():
        return 'info';
      case GameMode[GameMode.PRIVATE].toString():
        return 'danger';
      case GameMode[GameMode.FREE].toString():
        return 'primary';
    }
    return 'info';
  }

  classTxnType(type: string): string {
    switch (type) {
      case TransactionType[TransactionType.CREDIT].toString():
        return 'info';
      case TransactionType[TransactionType.DEBIT].toString():
        return 'danger';
    }
    return 'success';
  }

  classTxnStatus(type: string): string {
    switch (type) {
      case TransactionStatus[TransactionStatus.PENDING].toString():
        return 'warning';
      case TransactionStatus[TransactionStatus.SUCCESS].toString():
        return 'info';
      case TransactionStatus[TransactionStatus.CANCELLED].toString():
        return 'danger';
      case TransactionStatus[TransactionStatus.REJECTED].toString():
        return 'success';
      case TransactionStatus[TransactionStatus.FAILED].toString():
        return 'primary';
    }
    return 'info';
  }

  classTxnMode(type: string): string {
    switch (type) {
      case TransactionMode[TransactionMode.GAME].toString():
      case TransactionMode[TransactionMode.REFUND].toString():
        return 'primary';
      case TransactionMode[TransactionMode.ADMIN].toString():
        return 'warning';
      case TransactionMode[TransactionMode.DEPOSIT].toString():
        return 'info';
      case TransactionMode[TransactionMode.WITHDRAWAL].toString():
        return 'danger';
    }
    return 'info';
  }

  getImageMimeTypes(): string[] {
    return this.imageMimeTypes;
  }

  getVideoMimeTypes(): string[] {
    return this.videoMimeTypes;
  }

  getSupportedExtensions() {
    let extensions = [];
    for (const mimeType of this.imageMimeTypes) {
      extensions.push(mimeTypes.getExtension(mimeType));
    }

    for (const mimeType of this.videoMimeTypes) {
      extensions.push(mimeTypes.getExtension(mimeType));
    }

    return extensions.join(', ');
  }

  getChartFilterValues() {
    return [
      ChartFilterType.LastSevenDays,
      ChartFilterType.LastMonth,
      ChartFilterType.LastSixMonths,
      ChartFilterType.LastYear,
    ];
  }

  getSupportedMimeTypes() {
    let mimeTypes = [...this.imageMimeTypes, ...this.videoMimeTypes];
    return mimeTypes.join(', ');
  }

  getTransactionModes() {
    let array = [];
    for (let key in Object.values(TransactionMode)) {
      if (TransactionMode[key]) {
        array.push({ value: Number(key), key: TransactionMode[key] });
      }
    }
    return array;
  }

  getTransactionStatus(completed = false) {
    let array = [];
    for (let key in Object.values(TransactionStatus)) {
      if (TransactionStatus[key]) {
        array.push({ value: Number(key), key: TransactionStatus[key] });
      }
    }

    if (completed) {
      array = array.filter(
        (status) =>
          status.value != TransactionStatus.REQUESTED &&
          status.value != TransactionStatus.PENDING
      );
    }
    return array;
  }
}
