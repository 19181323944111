import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';
import { AdminType } from '../../../../../modules/database/admin/admin-type.enum';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

@Injectable()
export class MenuItems {
  MENUITEMS: any[] = [];

  private dashboard = {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard',
  };

  private admin = {
    state: 'admins',
    name: 'Admin Management',
    type: 'link',
    icon: 'person',
  };

  private master = {
    state: 'masters',
    name: 'Host Management',
    type: 'link',
    icon: 'group',
  };

  private user = {
    state: 'users',
    name: 'User Management',
    type: 'link',
    icon: 'groups',
  };

  private transaction = {
    state: 'transactions',
    name: 'Transactions',
    type: 'link',
    icon: 'compare_arrows',
  };

  private cms = {
    state: 'cms',
    name: 'Content Management',
    type: 'link',
    icon: 'font_download',
  };

  private setting = {
    state: 'game-settings',
    name: 'Game Settings',
    type: 'link',
    icon: 'build',
  };

  private session = {
    state: 'sessions',
    name: 'Session Management',
    type: 'link',
    icon: 'sports_esports',
  };
  constructor(private storageService: StorageService) {
    this.updateMenus();
  }

  updateMenus() {
    const currentAdmin = this.storageService.getAdmin();

    if (currentAdmin && currentAdmin.adminType === AdminType.MAIN) {
      this.MENUITEMS = [
        this.dashboard,
        this.session,
        this.master,
        this.user,
        this.transaction,
        this.cms,
        this.setting,
        this.admin,
      ];
    } else {
      this.MENUITEMS = [
        this.dashboard,
        this.session,
        this.master,
        this.user,
        this.transaction,
      ];
    }
  }

  getMenuitem(): Menu[] {
    return this.MENUITEMS;
  }
}
