<h6 mat-dialog-title>Admin Profile</h6>
<mat-dialog-content>
  <div class="text-center">
    <img
      class="img-circle m-b-20"
      src="{{
        currentAdmin && currentAdmin.profile
          ? currentAdmin.profile
          : 'assets/images/users/1.jpg'
      }}"
      alt="user"
      width="150"
    />
  </div>

  <form [formGroup]="adminForm">
    <mat-form-field class="w-100">
      <input matInput placeholder="Name" formControlName="name" />
      <mat-error *ngIf="adminForm.controls.name.hasError('required')">
        Name is required.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100">
      <input
        type="email"
        matInput
        placeholder="Email"
        formControlName="email"
      />
      <mat-error *ngIf="adminForm.controls.email.hasError('email')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="adminForm.controls.email.hasError('required')">
        Email is required
      </mat-error>
    </mat-form-field>
  </form>
  <button
    mat-button
    mat-flat-button
    color="warn"
    [disabled]="!adminForm.valid"
    (click)="updateProfile()"
  >
    Submit
  </button>
  <button mat-button class="m-l-10" (click)="closeDialog()">Cancel</button>
</mat-dialog-content>
