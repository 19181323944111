<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
  <img
    src="{{
      admin && admin.profile ? admin.profile : 'assets/images/users/1.jpg'
    }}"
    alt="user"
    class="profile-pic"
  />
</button>
<mat-menu #profile="matMenu" class="mymegamenu">
  <app-admin-menu></app-admin-menu>
</mat-menu>
