import { Injectable } from '@angular/core';
import { Admin } from '../../../../../modules/database/admin/admin.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  setAdmin(admin?: Admin | null) {
    if (admin) {
      localStorage.setItem('admin', btoa(JSON.stringify(admin)));
    }
  }

  getAdmin(): Admin | null {
    let data = localStorage.getItem('admin');

    if (data) {
      return JSON.parse(atob(data));
    } else {
      return null;
    }
  }

  setAccessToken(accessToken: string) {
    localStorage.setItem('accessToken', btoa(accessToken));
  }

  getAccessToken(): string {
    let data = localStorage.getItem('accessToken');
    if (!data) return '';
    return atob(data);
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', btoa(refreshToken));
  }

  getRefreshToken(): string {
    let data = localStorage.getItem('refreshToken');
    if (!data) return '';
    return atob(data);
  }
}
