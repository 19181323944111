import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenAuthorizationService implements HttpInterceptor {
  constructor(private storageService: StorageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('/api/')) {
      const modifiedReq = req.clone({
        headers: new HttpHeaders().set(
          'Authorization',
          `Bearer ${this.storageService.getAccessToken()}`
        ),
      });
      return next.handle(modifiedReq);
    } else {
      return next.handle(req);
    }
  }
}
