export enum LanguageLabel {
  ENGLISH = "English",
  HINDI = "हिंदी",
  GUJARATI = "ગુજરાતી",
}

export enum Languages {
  ENGLISH = "en",
  HINDI = "hi",
  GUJARATI = "gu",
}
