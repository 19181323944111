import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { ConfirmValidator } from 'src/app/shared/validators/confirm.validator';
import { AdminService } from 'src/app/shared/services/admin.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EncryptService } from 'src/app/shared/services/encrypt.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  isLoadingRecords = false;
  passwordForm!: FormGroup;
  hideOldPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  color: ThemePalette = 'accent';

  constructor(
    private formBuilder: FormBuilder,
    private adminService: AdminService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private encryptService: EncryptService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.passwordForm = this.formBuilder.group(
      {
        oldPassword: new FormControl(null, [Validators.required]),
        newPassword: new FormControl(null, [Validators.required]),
        confirmPassword: new FormControl(null, [Validators.required]),
      },
      {
        validators: [ConfirmValidator('newPassword', 'confirmPassword')],
      }
    );
  }

  closeDialog(): void {
    this.dialogRef.close({ event: 'Cancel' });
  }

  changePassword() {
    if (this.passwordForm.valid) {
      this.spinner.show();
      this.adminService
        .changePassword(
          this.encryptService.encrypt(
            this.passwordForm.value.oldPassword.trim()
          ),
          this.encryptService.encrypt(
            this.passwordForm.value.newPassword.trim()
          )
        )
        .subscribe(
          (response) => {
            this.passwordForm.reset();
            this.spinner.hide();
            this.closeDialog();
          },
          (error) => {
            this.spinner.hide();
            console.error(error);
          }
        );
    }
  }
}
