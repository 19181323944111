export enum SocketEmit {
  // Generic
  _Pong = "_pong",
  OnlineUsers = "onlineUsers",

  // Thirteen
  RoundStarted = "roundStarted",
  RoundEnded = "roundEnded",
  CardShown = "cardShown",
  TurnEnded = "turnEnded",
  BiddingOpened = "biddingOpened",
  BiddingClosed = "biddingClosed",
  BoardBids = "boardBids",
}
