<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<ngx-spinner
  bdColor="rgba(0,0,0,0.5)"
  size="medium"
  color="#ffffff"
  type="timer"
  [fullScreen]="true"
></ngx-spinner>
<router-outlet>
  <app-spinner></app-spinner>
</router-outlet>
