import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { retry, map, catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { UtilityService } from './utility.service';
import { Admin } from '../../../../../modules/database/admin/admin.model';
import { FilterQuery } from '../../../../../modules/models';
import * as queryString from 'query-string';
import { User } from '../../../../../modules/database/user/user.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  onProfileChange: Subject<Admin> = new Subject();

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private utilService: UtilityService
  ) {}

  login(email: string, password: string): Observable<Admin> {
    return this.http
      .post(`${environment.apiBaseUrl}/api/admins/login`, {
        email: email,
        password: password,
      })
      .pipe(
        retry(1),
        map((response: any) => {
          this.utilService.toast(response.message);
          return response.data;
        }),
        catchError((error) => {
          console.log(error);
          const message = error.error.message
            ? error.error.message
            : 'Something went wrong.';
          this.utilService.toast(message);
          return throwError(message);
        })
      );
  }

  refreshToken(): Observable<Admin> {
    return this.http
      .post(`${environment.apiBaseUrl}/api/admins/refresh-token`, {
        accessToken: this.storageService.getAccessToken(),
        refreshToken: this.storageService.getRefreshToken(),
      })
      .pipe(
        retry(1),
        map((response: any) => {
          this.utilService.toast(response.message);
          return response.data;
        }),
        catchError((error) => {
          console.log(error);
          const message = error.error.message
            ? error.error.message
            : 'Something went wrong.';
          this.utilService.toast(message);
          return throwError(message);
        })
      );
  }

  getProfile(): Observable<Admin> {
    return this.http.get(`${environment.apiBaseUrl}/api/admins/profile`).pipe(
      retry(1),
      map((response: any) => {
        this.onProfileChange.next(response.data);
        this.utilService.toast(response.message);
        return response.data;
      }),
      catchError((error) => {
        console.log(error);
        const message = error.error.message
          ? error.error.message
          : 'Something went wrong.';
        this.utilService.toast(message);
        return throwError(message);
      })
    );
  }

  updateProfile(admin: Admin): Observable<Admin> {
    return this.http
      .put(`${environment.apiBaseUrl}/api/admins/profile`, admin)
      .pipe(
        retry(1),
        map((response: any) => {
          this.onProfileChange.next(response.data);
          this.utilService.toast(response.message);
          return response.data;
        }),
        catchError((error) => {
          console.log(error);
          const message = error.error.message
            ? error.error.message
            : 'Something went wrong.';
          this.utilService.toast(message);
          return throwError(message);
        })
      );
  }

  changePassword(oldPassword: string, newPassword: string): Observable<Admin> {
    return this.http
      .post(`${environment.apiBaseUrl}/api/admins/change-password`, {
        oldPassword,
        newPassword,
      })
      .pipe(
        retry(1),
        map((response: any) => {
          this.utilService.toast(response.message);
          return response.data;
        }),
        catchError((error) => {
          console.log(error);
          const message = error.error.message
            ? error.error.message
            : 'Something went wrong.';
          this.utilService.toast(message);
          return throwError(message);
        })
      );
  }

  getAdmins(filter: FilterQuery): Observable<{
    admins: Admin[];
    totalRecords: number;
  }> {
    const query = queryString.stringify({ ...filter });
    return this.http.get(`${environment.apiBaseUrl}/api/admins?${query}`).pipe(
      retry(1),
      map((response: any) => {
        return response.data;
      }),
      catchError((error) => {
        console.log(error);
        const message = error.error.message
          ? error.error.message
          : 'Something went wrong.';
        this.utilService.toast(message);
        return throwError(message);
      })
    );
  }

  createAdmin(admin: Admin): Observable<Admin> {
    return this.http
      .post(`${environment.apiBaseUrl}/api/admins/create`, admin)
      .pipe(
        retry(1),
        map((response: any) => {
          return response.data;
        }),
        catchError((error) => {
          console.log(error);
          const message = error.error.message
            ? error.error.message
            : 'Something went wrong.';
          this.utilService.toast(message);
          return throwError(message);
        })
      );
  }

  deleteAdmin(id: String): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/api/admins/${id}`).pipe(
      retry(1),
      map((response: any) => {
        this.utilService.toast(response.message);
        return true;
      }),
      catchError((error) => {
        console.log(error);
        const message = error.error.message
          ? error.error.message
          : 'Something went wrong.';
        this.utilService.toast(message);
        return throwError(message);
      })
    );
  }

  getBalanceOfUsers(masterId: string): Observable<User[]> {
    return this.http
      .get(`${environment.apiBaseUrl}/api/users/${masterId}/balance`)
      .pipe(
        retry(1),
        map((response: any) => {
          return response.data;
        }),
        catchError((error) => {
          console.log(error);
          const message = error.error.message
            ? error.error.message
            : 'Something went wrong.';
          this.utilService.toast(message);
          return throwError(message);
        })
      );
  }
}
