import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AdminService } from './admin.service';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenRefreshService implements HttpInterceptor {
  constructor(
    private router: Router,
    private adminService: AdminService,
    private storageService: StorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 &&
          req.url !== `${environment.apiBaseUrl}/api/admins/login`
        ) {
          return this.adminService.refreshToken().pipe(
            mergeMap((admin) => {
              this.storageService.setAdmin(admin);
              if (
                admin.token &&
                admin.token.accessToken &&
                admin.token.refreshToken
              ) {
                this.storageService.setAccessToken(admin.token.accessToken);
                this.storageService.setRefreshToken(admin.token.refreshToken);
              }

              let accessToken =
                admin.token && admin.token.accessToken
                  ? admin.token.accessToken
                  : '';
              const cloneRequest = req.clone({
                setHeaders: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              return next.handle(cloneRequest);
            })
          );
        }

        if (error.status === 417) {
          this.storageService.setAdmin();
          this.storageService.setAccessToken('');
          this.storageService.setRefreshToken('');
          this.router.navigate(['/auth']);
        }

        return throwError(error);
      })
    );
  }
}
