import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  icons = [
    { name: 'wallet_minus', path: '../assets/svgs/wallet-minus.svg' },
    { name: 'wallet_plus', path: '../assets/svgs/wallet-plus.svg' },
    { name: 'inactive', path: '../assets/svgs/inactive.svg' },
    { name: 'active', path: '../assets/svgs/active.svg' },
    { name: 'logout', path: '../assets/svgs/logout.svg' },
    { name: 'share', path: '../assets/svgs/share.svg' },
    { name: 'cut', path: '../assets/svgs/cut.svg' },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    }
  }
}
