export enum SocketListen {
  // Generic
  Connection = "connection",
  _Ping = "_ping",
  Disconnect = "disconnect",
  InBackground = "inBackground",
  OnlineUsers = "onlineUsers",

  // Thirteen
  StartRound = "startRound",
  ShowCard = "showCard",
  JoinOngoing = "joinOngoing",

  BidValue = "bidValue",
}
